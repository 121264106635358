import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Maintenance = () => {
    const { t } = useTranslation();
    return (
        <>
            <PageContainer>
                <MaintenanceImage src="/assets/images/logo_colors.png" alt="Maintenance" />
                <Title>{t("maintenance.title")}</Title>
                <Message>{t("maintenance.content")}</Message>
                {/* <ContactInfo>If you need immediate assistance, please contact support@example.com</ContactInfo> */}
            </PageContainer>
        </>
    );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6;
  text-align: center;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
`;

const Message = styled.p`
  font-size: 1.25rem;
  max-width: 75vw;
  color: #666;
  margin-bottom: 40px;
`;

const MaintenanceImage = styled.img`
  width: 40vw;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

const ContactInfo = styled.p`
  font-size: 1rem;
  color: #999;
`;

export default Maintenance;
