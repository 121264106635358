import styled from 'styled-components';

function FeatureSection({ title, paragraph, imgSrc, imageFirst }: { title: string, paragraph: string, imgSrc: string, imageFirst: boolean }) {
  return (
    <Section imageFirst={imageFirst}>
      <ImageContainer>
        <StyledImage src={imgSrc} alt="Feature" />
      </ImageContainer>
      <Content>
        <Title>{title}</Title>
        <Paragraph>
          {paragraph}
        </Paragraph>
      </Content>
    </Section>
  );
};

const Section = styled.div<{ imageFirst: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #000;
  padding: 50px 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;

    ${({ imageFirst }) => imageFirst && `
      & > div:first-child {
        order: 1;
      }
      & > div:last-child {
        order: 2;
      }
    `}

    ${({ imageFirst }) => !imageFirst && `
      & > div:first-child {
        order: 2;
      }
      & > div:last-child {
        order: 1;
      }
    `}
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  max-width: 600px;

  @media (max-width: 768px) {
    text-align: center;
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background: #000;
    position: absolute;
    left: 0;
    bottom: -10px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const Paragraph = styled.p`
  font-size: 1.05rem;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 600px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
  }
`;

const StyledImage = styled.img`
  width: 80%;
  height: auto;
  border: 5px solid #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  max-height: 45vh;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default FeatureSection;
