import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const ExtractSection = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <TextContainer>
        <Title>{t("extract_section.title")}</Title>
        <LongText>
          {t("extract_section.content")}
        </LongText>
      </TextContainer>
      <ImageContainer>
        <Image src="/assets/images/pk_power_red.jpg" alt="Description" />
      </ImageContainer>
    </Section>
  );
};

const Section = styled.div`
  display: flex;
  height: 100vh;
  background: #000000;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  position: relative;
  color: #fff;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: -10px;
    margin-bottom: 10px;
  }
`;

const LongText = styled.p`
  white-space: pre-line; /* This preserves the newline characters */
  font-size: 1rem;
  line-height: 1.5;
  max-width: 85%;
  color: #fff;
`;

const ImageContainer = styled.div`
  flex: 1;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 50vh;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default ExtractSection;
