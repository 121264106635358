import React from 'react';
import { useTranslation } from "react-i18next";
import Navbar from './components/navbar';
import FeatureSection from './components/featureSection';
import HoverTransitionDivs from './components/hoverDivs';
import TeamSection, { TeamMemberType } from './components/teamSection';
import ExtractSection from './components/extractSection';
import Footer from './components/footer';
import Maintenance from './components/maintenance';
import Support from './components/supportSection';

function App() {
  const { t } = useTranslation();
  const maintenanceActive = false;
  const teamMembers: Array<TeamMemberType> = [
    {
      name: t("our_team.julie.name"),
      role: t("our_team.julie.role"),
      photo: '/assets/images/julie_landry.jpg'
    },
    {
      name: t("our_team.gerard.name"),
      role: t("our_team.gerard.role"),
      photo: '/assets/images/gerard_redziniak.jpg'
    },
    {
      name: t("our_team.marc.name"),
      role: t("our_team.marc.role"),
      photo: '/assets/images/marc_feuilloley.jpg'
    }
  ];
  const teamMembers2: Array<TeamMemberType> = [
    {
      name: t("our_team.hichem.name"),
      role: t("our_team.hichem.role"),
      photo: '/assets/images/hichem_henchiri.jpg'
    },
    {
      name: t("our_team.jean.name"),
      role: t("our_team.jean.role"),
      photo: '/assets/images/jean_landry.jpg'
    },
    {
      name: t("our_team.others"),
      role: '',
      photo: '/assets/images/collaborateur.jpg'
    }
  ];
  return (
    <>
      {maintenanceActive ? <Maintenance /> : <>     <Navbar />
        <FeatureSection
          title={t("whoarewe.title")}
          paragraph={t("whoarewe.content")}
          imageFirst={false}
          imgSrc="/assets/images/logo_colors.png" />
        <FeatureSection
          title={t("our_values.title")}
          paragraph={t("our_values.content")}
          imageFirst={true}
          imgSrc="/assets/images/values.jpg" />
        <center>
          <h1 className='section_title'>{t("our_offer.title")}</h1>
        </center>
        <HoverTransitionDivs />
        <ExtractSection />
        {/* <center>
          <h1 className='section_title'>{t("our_team.title")}</h1>
          <p style={{ maxWidth: "50vw" }}>{t("our_team.content_one")} {t("our_team.content_two")}</p>
        </center>
        <TeamSection teamMembers={teamMembers} />
        <TeamSection teamMembers={teamMembers2} /> */}
        <center>
          <h1 className='section_title'>{t("partners.title")}</h1>
        </center>
        <Support />
        <Footer /> </>
      }
    </>
  );
}

export default App;
