import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <HeaderContainer>
      <Logo src="/assets/images/logo.png" alt="Logo" />
      <Content>
        <Title>{t("navbar.title")}</Title>
        <Subtitle>{t("navbar.subtitle")}</Subtitle>
      </Content>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('/assets/images/fond_foret.jpg');
  background-size: cover;
  background-position: center;
  height: 80vh;

  @media (max-width: 768px) {
    height: 100vh;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* This adds the dark overlay */
    z-index: 1; /* Ensures the overlay is above the background but below content */
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  height: 10vh;
  z-index: 2; /* Ensures the logo is above the overlay */
`;

const Content = styled.div`
  position: relative;
  z-index: 2; /* Ensures the text is above the overlay */
  text-align: center;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  color: white;
  max-width: 85vh;
    @media (max-width: 768px) {
    max-width: 92vw;
    }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 3rem;
  font-family: "Poppins Bold", sans-serif;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h2`
  margin-top: 3vh;
  font-size: 1.5rem;
  font-family: "Poppins Light", sans-serif;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export default Header;
