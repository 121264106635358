import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <CompanyInfo>
        <CompanyName>{t("footer.company_name")}</CompanyName>
        <CompanyDetails>
          {t("footer.address")}<br />
          {t("footer.email")}
        </CompanyDetails>
      </CompanyInfo>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background: #000;
  color: #fff;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const CompanyInfo = styled.div`
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const CompanyName = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

const CompanyDetails = styled.p`
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export default Footer;