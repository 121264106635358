import styled from 'styled-components';
import { useTranslation } from "react-i18next";


const HoverTransitionDivs = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <HoverDiv>
          <BackgroundImage src="/assets/images/cosmetiques.jpg" />
          <Overlay />
          <TextContainer>
            <ShortText>{t("our_offer.card_one.title")}</ShortText>
            <LongText>{t("our_offer.card_one.content")}</LongText>
          </TextContainer>
        </HoverDiv>
        <HoverDiv>
          <BackgroundImage src="/assets/images/alt_pla.jpg" />
          <Overlay />
          <TextContainer>
            <ShortText>{t("our_offer.card_two.title")}</ShortText>
            <LongText>{t("our_offer.card_two.content")}</LongText>
          </TextContainer>
        </HoverDiv>
      </Container>
      <Container>
        <HoverDiv>
          <BackgroundImage src="/assets/images/more_research.jpg" />
          <Overlay />
          <TextContainer>
            <ShortText>{t("our_offer.card_three.title")}</ShortText>
            <LongText>{t("our_offer.card_three.content")}</LongText>
          </TextContainer>
        </HoverDiv>
      </Container>
    </>
  );
};

const Overlay = styled.div`
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  transition: bottom 0.5s ease;
  opacity: 0.8;
  z-index: 2;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 67.5%;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 90%;
  text-align: center;
  z-index: 3;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;

  @media (max-width: 768px) {
    top: 90%;
    transform: translate(-50%, -62.5%);
  }
`;

const ShortText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  transition: all 0.5s ease;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const LongText = styled.div`
  font-size: 1rem;
  opacity: 0;
  transition: all 0.5s ease;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-top: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
`;

const HoverDiv = styled.div`
  position: relative;
  width: 35vw;
  height: 20vw;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 20px;

  &:hover ${Overlay} {
    bottom: 0;
  }

  &:hover ${TextContainer} {
    top: 50%;
  }

  &:hover ${ShortText} {
    opacity: 0;
  }

  &:hover ${LongText} {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 80vw;
    height: 45vw;
  }

  @media (max-width: 480px) {
    width: 90vw;
    height: 50vw;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.7);
  transition: all 0.5s ease;
  z-index: 1;
`;

export default HoverTransitionDivs;
