import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Support = () => {
    const { t } = useTranslation();

    const partnersList: Array<{ name: string, photoUrl: string }> = [
        {
            name: t("partners.frenchtech"),
            photoUrl: '/assets/images/ftvdl.png'
        },
        {
            name: t("partners.stationf"),
            photoUrl: '/assets/images/stationf.png'
        }
    ]

    return (
        <Section>
            {partnersList.map((partner, index) => (
                <TeamMember key={index}>
                    <PhotoContainer>
                        <Photo src={partner.photoUrl} alt={partner.name} />
                    </PhotoContainer>
                    <Name>{partner.name}</Name>
                </TeamMember>
            ))}
        </Section>
    );
}

export default Support;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 20px;
  height: 150px;
  flex: 1 1 200px;
  max-width: 250px;
`;

const PhotoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  
`;

const Photo = styled.img`
  max-height: 100px;
  max-width: 200px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Name = styled.div`
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
`;
